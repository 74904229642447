<template>
  <div id="product">
    <Header now-nav="/product" ref="header" @thisPageType="thisPageType"></Header>
    <div class="headerBox">
      <img :src="baseUrl+'imges/technology/product/productHeader.png'" alt="" class="imgProductHead">
      <h4  data-aos="fade-down" data-aos-duration="1000">产品与服务</h4>
      <p  data-aos="fade-down" data-aos-duration="1000">致力于工业物联网生态闭环，具备高兼容和柔性适配能力</p>
    </div>
    <!--  内容区  -->
    <div class="contentBox">
      <div class="navHead">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/technology' }">产品与服务</el-breadcrumb-item>
          <el-breadcrumb-item>{{ thisProductName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="productBox">
        <div class="leftProducted" v-if="thisProductName==='RA52-CN工业(5G)路由器'">
          <p @click="goAnchor('summarize','tab1')" :class="tab1?'isActive':''" style="margin-top: 30px">产品概述</p>
          <p @click="goAnchor('topologicalGraph','tab2')" :class="tab2?'isActive':''">组网拓扑图</p>
          <p @click="goAnchor('function','tab3')" :class="tab3?'isActive':''">功能特点及优势</p>
          <p @click="goAnchor('application','tab5')" :class="tab5?'isActive':''">应用场景</p>
          <p @click="goAnchor('technology','tab4')" :class="tab4?'isActive':''">技术参数</p>
          <p :class="tab4?'isActive':''">
            <a :href="rstPdf" download="蓉视通5G路由器 RA52-CN 规格书.pdf">资料下载</a>
          </p>
        </div>
        <div class="leftProduct" v-else>
          <p @click="goAnchor('summarize','tab1')" :class="tab1?'isActive':''" style="margin-top: 30px">产品概述</p>
          <p @click="goAnchor('topologicalGraph','tab2')" :class="tab2?'isActive':''">组网拓扑图</p>
          <p @click="goAnchor('function','tab3')" :class="tab3?'isActive':''">功能特点及优势</p>
          <p @click="goAnchor('application','tab5')" :class="tab5?'isActive':''">应用场景</p>
          <p @click="goAnchor('technology','tab4')" :class="tab4?'isActive':''">技术参数</p>
        </div>


        <div class="rightProduct">
          <!--   RST-1100型(4G工业路由)     -->
          <div class="productListBox" v-if="thisProductName==='RST-1100型(4G工业路由)'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/RA42-CN（4G）.png'" alt="">
                <div class="con">
                  <h2>RST-1100型(4G工业路由)</h2>
                  <p>RST-1100工业级3G/4G路由器是基于无线通信的工业级路由器，具有完备智能的软
                    件功能和全工业级硬件平台，自如应对远距离传输+近距离组网等复杂的无线通信需
                    求，为用户提供高可靠、高速、安全的网络接入和无线数据传输功能，其传输速率更快
                    更稳定，更能适应恶劣环境应用。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_RA42.png'" alt="" class="Mobile" style="width: 190px;height: 244px;margin-top: 52px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
              <p>
                RST-1100工业级3G/4G路由器是基于无线通信的工业级路由器，具有完备智能的软件功能和全工业级硬件平台，自如应对远距离传输+近距离组网等复杂的无线通信需求，为用户提供高可靠、高速、安全的网络接入和无线数据传输功能，其传输速率更快更稳定，更能适应恶劣环境应用。</p>
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph"  style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/product-rst1100-net.png'" alt="">
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <img :src="baseUrl+'product/product-rst1100-function01.png'" alt="">
                <div class="con">
                  <h2>1.工业设计</h2>
                  <p>
                    采用高性能工业级32位通信处理器无线模块，金属外壳，保护等级IP30，外壳和系统安全隔离，特别适合于工控现场的应用，天线接口防雷保护，可抵御极端温度、电压不稳、高电磁辐射、雨水灰尘侵袭等恶劣条件，保障远端设备与监控中心的持续通讯。</p>
                </div>
              </div>
            </div>
            <!--功能特点及优势2-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <div class="con" style="margin-left: 0">
                  <h2>2.高可靠、无间断的网络通讯</h2>
                  <p>
                    采用高性能工业级32位通信处理器无线模块，金属外壳，保护等级IP30，外壳和系统安全隔离，特别适合于工控现场的应用，天线接口防雷保护，可抵御极端温度、电压不稳、高电磁辐射、雨水灰尘侵袭等恶劣条件，保障远端设备与监控中心的持续通讯。</p>
                </div>
                <img :src="baseUrl+'product/product-rst1100-function02.png'" alt="" style="margin-top: 30px">
              </div>
            </div>
            <!--功能特点及优势3-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <img :src="baseUrl+'product/product-rst1100-function03.png'" alt="" style="margin-top: 30px">
                <div class="con">
                  <h2>3.运维便捷，管理高效</h2>
                  <p>
                    支持远程管理、维护和升级，支持参数备份及导入，支持私钥导入/导出配置参数。提供系统本地日志和远程日志发送，实现网络实时监控，自动生成业务统计报表，为运营决策提供有力依据。支持网管功能，支持上下线检测、实时监控流量，持定时管理和数据触发上线功能，支持LCP检测、ICMP检测、接口流量检测、心跳包检测等链路检测功能，提供用户配置信息防丢失功能。</p>
                </div>

              </div>
            </div>
            <!--功能特点及优势4-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <div class="con" style="margin-left: 0">
                  <h2>4.高安全性</h2>
                  <p>RST-1100为业务数据设计提供了完备的安全保护方案，产品支持IPSec, DMVPN,
                    L2TP, Open VPN等多种VPN加密模式，以确保通信链路数据安全。提供防火墙功
                    能，以及保障设备管理安全的用户分级授权机制。</p>
                </div>
                <img :src="baseUrl+'product/product-rst1100-function04.png'" alt="" style="margin-top: 30px">
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <p>xxxxxxxxxxxxxx</p>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstRA4GPdf" download="蓉视通4G路由器 RA52-CN 规格书.pdf">RA52-CN工业4G路由器参数表</a>
              <p>
                RA52-CN工业4G路由器参数表
              </p>
              <div class="MobileButton">
                <a :href="rstRA4GPdf" download="蓉视通4G路由器 RA52-CN 规格书.pdf">下载</a>
              </div>
            </div>
          </div>

          <!--   RA42-CN(4G工业路由)     -->
          <div class="productListBox" v-if="thisProductName==='工业4G路由器'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/RA42-CN（4G）.png'" alt="">
                <div class="con">
                  <h2>工业4G路由器</h2>
                  <p>RA42-CN是工业级无线路由器，应用于智能货柜，智能水务，智慧电力等多个行业场景。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_RA42.png'" alt="" class="Mobile" style="width: 190px;height: 244px;margin-top: 52px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
              <p>RA42-CN采用工业元器件，性能稳定可靠，支持多种VPN加密，支持GPS定位，支持无线AP模式和网络备份功能。</p>
<!--              <p>蓉视通RST-KT100系列工业级路由器/DTU产品，支持2G/3G/4G LTE数据接入，提供LAN/RS232/RS485/GPIOs接口，为工业控制、远程设备管理应用提供稳定可靠的数据通道。</p>-->
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph" style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/4GTuopu.png'" style="width: 100%; height: 95%" alt="" >
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <img :src="baseUrl+'product/product-rst1100-function01.png'" alt="">
                <div class="con">
                  <h2>1.工业设计</h2>
                  <p>
                    采用高性能工业级32位通信处理器无线模块，金属外壳，保护等级IP30，外壳和系统安全隔离，特别适合于工控现场的应用，天线接口防雷保护，可抵御极端温度、电压不稳、高电磁辐射、雨水灰尘侵袭等恶劣条件，保障远端设备与监控中心的持续通讯。</p>
                </div>
              </div>
            </div>
            <!--功能特点及优势2-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <div class="con" style="margin-left: 0">
                  <h2>2.高可靠、无间断的网络通讯</h2>
                  <p>
                    采用高性能工业级32位通信处理器无线模块，金属外壳，保护等级IP30，外壳和系统安全隔离，特别适合于工控现场的应用，天线接口防雷保护，可抵御极端温度、电压不稳、高电磁辐射、雨水灰尘侵袭等恶劣条件，保障远端设备与监控中心的持续通讯。</p>
                </div>
                <img :src="baseUrl+'product/product-rst1100-function02.png'" alt="" style="margin-top: 30px">
              </div>
            </div>
            <!--功能特点及优势3-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <img :src="baseUrl+'product/product-rst1100-function03.png'" alt="" style="margin-top: 30px">
                <div class="con">
                  <h2>3.运维便捷，管理高效</h2>
                  <p>
                    支持远程管理、维护和升级，支持参数备份及导入，支持私钥导入/导出配置参数。提供系统本地日志和远程日志发送，实现网络实时监控，自动生成业务统计报表，为运营决策提供有力依据。支持网管功能，支持上下线检测、实时监控流量，持定时管理和数据触发上线功能，支持LCP检测、ICMP检测、接口流量检测、心跳包检测等链路检测功能，提供用户配置信息防丢失功能。</p>
                </div>

              </div>
            </div>
            <!--功能特点及优势4-->
            <div class="productListFunction productListFunctionSmall">
              <div class="productListContent">
                <div class="con" style="margin-left: 0">
                  <h2>4.高安全性</h2>
                  <p>RST-1100为业务数据设计提供了完备的安全保护方案，产品支持IPSec, DMVPN,
                    L2TP, Open VPN等多种VPN加密模式，以确保通信链路数据安全。提供防火墙功
                    能，以及保障设备管理安全的用户分级授权机制。</p>
                </div>
                <img :src="baseUrl+'product/product-rst1100-function04.png'" alt="" style="margin-top: 30px">
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/4G-Pipeline.jpeg'">
                  <p>管道监控</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/4G-Distance.jpeg'">
                  <p>远程监测</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/4G-Container.jpeg'">
                  <p>智慧货柜</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/4G-Farming.jpeg'">
                  <p>智慧农业</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstRA4GPdf" download="蓉视通4G路由器 RA52-CN工业4G路由器参数表.pdf">RA52-CN工业4G路由器参数表.pdf</a>
              <p>
                RA52-CN工业4G路由器参数表.pdf
              </p>
              <div class="MobileButton">
                <a :href="rstRA4GPdf" download="蓉视通4G路由器 RA52-CN工业4G路由器参数表.pdf">下载</a>
              </div>
            </div>
          </div>

          <!--   双模5G路由器     -->
          <div class="productListBox" v-if="thisProductName==='双模5G路由器'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/双模5G.png'" alt="">
                <div class="con">
                  <h2>5G双模工业路由器</h2>
                  <p>RX52-CN是一款5G双模工业路由器，能够通过链路监测与聚合。提供可靠的高速上下行速度，为高清直播，应急抢险，远程诊断等新兴场景提供安全稳定的网络部署。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_双模5G.png'" alt="" class="Mobile" style="width: 162px;height: 213px;margin-top: 56px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
              <p>RX52-CN支持多网络模块聚合，支持NA/NSA组网，支持有线/无线网络智能切换，支持多种VPN加密，支持云平台管理，流量管里，远程配置，批量配置，链路监测，断线重连等功能，在网速性能，传输加密方面表现卓越。</p>
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph"  style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/5G02Tuopu.png'" style="width: 100%; height: 95%" alt="" >
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction productListFunctionMin">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <div class="con" style="margin: 0">
                  <p>
                    <span>·千兆高速网络带宽</span>
                    <span>·支持云平台远程管理 </span>
                    <span>·双模组应用，提供高稳定/高可靠的通讯网络</span>
                    <span>·工业铝合金材质，抗高低温，经久耐用  </span>
                    <span>·支持多种VPN加密 </span>
                    <span>·支持QOS流量管理</span>
                  </p>
                </div>
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/5G-Discrete.jpeg'">
                  <p>离散工业</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/5G-SmartGrid.webp'">
                  <p>智慧电网</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/5G-Transportation.webp'">
                  <p>智慧交通</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/5G-HeavyIndustry.webp'">
                  <p>智慧重工</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstRX5GPdf" download="RX52-CN工业5G路由器参数表.pdf">RX52-CN工业5G路由器参数表.pdf</a>
              <p>
                RX52-CN工业5G路由器参数表.pdf
              </p>
              <div class="MobileButton">
                <a :href="rstRX5GPdf" download="RX52-CN工业5G路由器参数表.pdf">下载</a>
              </div>
            </div>
          </div>

          <!--   便携式应急灯     -->
          <div class="productListBox" v-if="thisProductName==='便捷式应急灯'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/light.png'" alt="">
                <div class="con">
                  <h2>便携式应急灯</h2>
                  <p>蓉视通便携式应急灯采用高强度铝合金及镁合金精密加工而成，超硬度阳极点镀锌外观表面，防爆耐用。灯头有多种模式可选，方便搜救及紧急照明。顶端配有高清4G一体化摄像机，可全天候摄像卫星定位。通过手机网络，卫星通讯网络进行信号传输，集照明、现场实况传播、人员聚集等功能于一体，为第一时间确认现场信息提供帮助。整机体积小、重量轻、便于携带，使用安装不受场地限制。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_light.png'" alt="" class="Mobile" style="width: 208px;height: 250px;margin-top: 127px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize" style="height: 150px">
              <h2 class="rightBorder">产品概述</h2>
              <p style="height: 210px">蓉视通便携式应急灯采用高强度铝合金及镁合金精密加工而成，超硬度阳极点镀锌外观表面，防爆耐用。灯头有多种模式可选，方便搜救及紧急照明。顶端配有高清4G一体化摄像机，可全天候摄像卫星定位。通过手机网络，卫星通讯网络进行信号传输，集照明、现场实况传播、人员聚集等功能于一体，为第一时间确认现场信息提供帮助。整机体积小、重量轻、便于携带，使用安装不受场地限制。</p>
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph"  style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/lightTuopu.png'" style="width: 100%; height: 95%" alt="" >
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction productListFunctionMid">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <div class="con" style="margin: 0">
                  <p>
                  <span>·位置查询：根据设备的内置GPS定位芯片（订制），接入地图API，进而为客户提供应急设备位置信息和分布情况。</span>
                  <span>·电量检测：对应急照明设备的电量进行实时监测，当其低于设定值时进行报警提示充电，有效防止设备电量不足的问题。</span>
                  <span>·亮度调节：可根据现场需要对照明亮度进行调节。</span>
                  <span>·无源续航：配置便携式太阳能板和储能包，在各种环境下实现无源充电，延长应急灯续航时间，应对各种应急特殊情况。</span>
                  <span>·无线传输：采用4G无线传输方式，支持高清视频远程及时传输，保证更多应急场景使用。</span>
                  <span>·图像处理：通过智能算法对图像进行处理，保证视频效果干净清爽、噪点低，同时还具有强光抑制、防抖、透雾等功能。</span>
                  <span>·远程控制：在客户端界面根据监控需求，调节监控视角，缩放监控距离，助力用户了解现场状况，更好实现远程应急指挥。</span>
                  <span>·实时监控：使用带云台旋转的高清摄像头，可实现360度无死角监控和流畅稳定的播放。</span>
                  <span>·大屏显示：与应急管理和应急调度系统对接，通过大屏显示，及时掌握现场状况，结合应急管理方案和应急调度预案，从而更大程度降低事故或灾害损失。</span>
                  </p>
                </div>
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication productListApplicationHeight">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/Earthquake.jpeg'">
                  <p>地震救灾</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/Flood.jpeg'">
                  <p>洪涝救援</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/Fire.jpeg'">
                  <p>火灾救援</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/Traffic.jpeg'">
                  <p>交通救援</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/Colliery.jpeg'">
                  <p>煤矿事故救灾</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/Medical.jpeg'">
                  <p>医疗救援</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology ">
              <h2 class="rightBorder">技术参数</h2>
              <img :src="baseUrl+'product/product-light-net.png'" alt="" style="width: 80%">
            </div>
          </div>

          <!--   RA52-CN工业(5G)路由器     -->
          <div class="productListBox" v-if="thisProductName==='RA52-CN工业(5G)路由器'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/RA52-CN.png'" alt="">
                <div class="con">
                  <h2>RA52-CN工业(5G)路由器</h2>
                  <p>RA52-CN工业级(5G)路由器，采用5G技术通信，具有高性能，高可靠，高稳定等优点。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_RA52_CN.png'" alt="" class="Mobile">

              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
              <p>RA52-CN采用高性能CPU，支持千兆LAN/WAN网口的5G通信，支持VPDN、APN快速接入，采用有QOS流量管理功能和防火墙配置，为远程设备和数据中心之间的联网提供安全高速的无线连接。</p>
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph" style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/5G01Tuopu.png'" style="width: 100%; height: 95%" alt="">
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction productListFunctionMax">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <div class="con" style="margin: 0">
                  <p>
                    <span>·5G全网通，超高速网络接入</span>
                    <span>·千兆网口LAN/WAN</span>
                    <span>·工业串口RS485、RS232、USB2.0</span>
                    <span>·VPDN、APN快速接入</span>
                    <span>·数据串口透传</span>
                    <span>·QOS流量管理</span>
                    <span>·防火墙配置</span>
                    <span>·python二次开发</span>
                    <span>·高性能CPU，工业级设计</span>
                  </p>

                </div>
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/RA52-AGV.jpeg'">
                  <p>AGV车</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/RA52-Industrialize.jpeg'">
                  <p>工业自动化</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/RA52-Monitor.jpeg'">
                  <p>指挥监测</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/RA52-Robot.jpeg'">
                  <p>智能机器人</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstRA5GPdf" download="蓉视通5G路由器 RA52-CN工业5G路由器参数表.pdf">RA52-CN工业5G路由器参数表.pdf</a>
              <p>
                RA52-CN工业5G路由器参数表.pdf
              </p>
              <div class="MobileButton">
                <a :href="rstRA5GPdf" download="蓉视通5G路由器 RA52-CN工业5G路由器参数表.pdf">下载</a>
              </div>
            </div>
          </div>

          <!--   DTU-Z0115     -->
          <div class="productListBox" v-if="thisProductName==='DTU-Z0115'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/DTU-Z0115.png'" alt="">
                <div class="con">
                  <h2>DTU-Z0115</h2>
                  <p>DTU-Z0115 数据传输单元，是专门用于将串口数据转换为IP数据或将IP数据转换为串口数据通过无线通信网络进行传送的无线终端设备。</p>
                </div>
                <img :src="baseUrl+'imges/technology/product/M_DTU.png'" alt="" class="Mobile" style="width: 218px;height: 188px;margin-top: 69px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
              <p>DTU-Z0115是将RS232/485采集的数据通过GPRS/3G/4G发送到云端服务器进行数据交互的传输设备.它支持串口透明传输，断线自动重连，并支持私有协议，数据本地存储，远程配置及本地配置，进而实现远程升级和状态查询，是工业应用领域远程数据采集和控制的理想选择。</p>
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph" style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/DTUTuopu.png'" style="width: 100%; height: 95%" alt="">
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction productListFunctionMin">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <div class="con" style="margin: 0">
                  <p>
                  <span>·支持串口透明传输，断线自动重连</span>
                  <span>·支持多数据中心</span>
                  <span>·支持私有协议，数据主动上传，数据本地存储，断线续传</span>
                  <span>·支持远程配置，本地配置，配置备份导出，批量配置</span>
                  <span>·支持远程升级，远程状态查询</span>
                  <span>·本地日志、远程日志</span>
                  <span>·支持第三方云平台接入</span>
                  </p>

                </div>
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/DTU管廊数据传输.png'">
                  <p>DTU管廊数据传输</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/DTU机房数据传输.png'">
                  <p>DTU机房数据传输</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/DTU配电柜数据传输.png'">
                  <p>DTU配电柜数据传输</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/DTU数据柜PLC数据传输.png'">
                  <p>DTU数据柜PLC数据传输</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstDTU" download="DN400 DTU参数表.pdf">Z0115 DTU参数表.pdf</a>
              <p>
                Z0115 DTU参数表.pdf
              </p>
              <div class="MobileButton">
                <a :href="rstDTU" download="DN400 DTU参数表.pdf">下载</a>
              </div>
            </div>
          </div>

          <!--   5G CPE     -->
          <div class="productListBox" v-if="thisProductName==='5G CPE'">
            <!--头部-->
            <div class="productListHead">
              <div class="productListContent">
                <img :src="baseUrl+'new/5GCPE.png'" alt="">
                <div class="con">
                  <h2>5G CPE</h2>
                    <p>RA54W-CN是一款面向工业的5GCPE,拥有高速可靠的网络性能和稳定的AP接入能力，为工业控制，智能制造的场景组网，提供优秀的解决方案。</p>
<!--                  <p>蓉视通RST-LD680系列4G LTE CAT 6室内CPE，掉线自恢复，保证数据连接的可靠稳定，保持永久在线。为用户设备提供安全可靠的数据接入通道。</p>-->
                </div>
                <img :src="baseUrl+'imges/technology/product/M_CPE.png'" alt="" class="Mobile" style="width: 219px;height: 192px;margin-top: 74px">
              </div>
            </div>
            <!--产品概述-->
            <div id="summarize" class="productListSummarize">
              <h2 class="rightBorder">产品概述</h2>
                <p>RA54W-CN集成了5G与wifi6技术，拥有可靠的组网性能。并提供专网专线，数据加密，流量管理，云平台管理，远程批量配置，链路监测，断线重连等丰富功能。</p>
<!--              <p>蓉视通RST-LD680系列4G LTE CAT 6室内CPE，掉线自恢复，保证数据连接的可靠稳定，保持永久在线。为用户设备提供安全可靠的数据接入通道。RST-LD680是一个高度先进的LTE室内CPE。设计用于满足住宅、商业和企业用户的综合数据需求。产品支持TD-LTE／FDD LTE release 9规范的标准实施。内置高增益天线，提供了广泛的服务覆盖率和高数据吞吐量。</p>-->
            </div>
            <!--组网方案拓扑图-->
            <div id="topologicalGraph" class="productListTopologicalGraph" style="height: 670px">
              <h2 class="rightBorder">组网拓扑图</h2>
              <img :src="baseUrl+'product/CPETuopu.png'" style="width: 100%; height: 95%" alt="">
            </div>
            <!--功能特点及优势1-->
            <div id="function" class="productListFunction productListFunctionMin">
              <h2 class="rightBorder">功能特点及优势</h2>
              <div class="productListContent">
                <div class="con" style="margin: 0">
                  <p>
                  <span>·保持永久在线</span>
                  <span>·内置数据链路维护功能，掉线自恢复</span>
                  <span>·RF高增益，内置高增益天线，适于弱信号区域，环境复杂使用</span>
                  <span>·支持固件升级</span>
                  <span>·支持UI界面升级</span>
                  <span>·Linux操作系统</span>
                  <span>·低功耗</span>
                  </p>

                </div>
              </div>
            </div>
            <!--应用场景-->
            <div id="application" class="productListApplication">
              <h2 class="rightBorder">应用场景</h2>
              <div class="app-Img">
                <div class="app-font">
                  <img :src="baseUrl+'product/CPE-SellGoods.jpeg'">
                  <p>直播带货</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/CPE-Warehouse.jpeg'">
                  <p>智慧仓库</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/CPE-Robot.jpeg'">
                  <p>智能机器人</p>
                </div>
                <div class="app-font">
                  <img :src="baseUrl+'product/CPE-Furniture.jpeg'">
                  <p>智能家具</p>
                </div>
              </div>
            </div>
            <!--技术参数-->
            <div id="technology" class="productListTechnology">
              <h2 class="rightBorder">技术参数</h2>
              <a :href="rstCPEPdf" download="蓉视通5GCPE RA54W-CN 5G CPE参数表.pdf">RA54W-CN 5G CPE参数表.pdf</a>
              <!--              <img src="../assets/img/product/product-rst1100-technology.png" alt="">-->
              <p>
                RA54W-CN 5G CPE参数表.pdf
              </p>
              <div class="MobileButton">
                <a :href="rstCPEPdf" download="蓉视通5GCPE RA54W-CN 5G CPE参数表.pdf">下载</a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <Footer
      @thisPageType="thisPageType"
    ></Footer>

  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import $ from 'jquery'

export default {
  name: "Productlist",
  data() {
    return {
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      rstPdf:require('../../public/RA52CN.pdf'),
      rstRA5GPdf:require('../../public/RA52-CN(5G).pdf'),
      rstRA4GPdf:require('../../public/RA42-CN(4G).pdf'),
      rstCPEPdf:require('../../public/CPE(5G).pdf'),
      rstDTU:require('../../public/DTU.pdf'),
      rstRX5GPdf:require('../../public/RX52-CN(5G).pdf'),

      //当前硬件产品名称
      thisProductName: this.$route.params.name || sessionStorage.getItem('curProduct')
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    sessionStorage.setItem('curProduct', this.thisProductName)
    this.$refs.header.openTechnology()
  },
  methods: {
    thisPageType(e) {
      this.thisProductName = e
    },
    //锚点定位
    goAnchor(id, e) {
      document.documentElement.scrollTop = $('#' + id).offset().top - 20;
      //选中效果
      if (e === "tab1") {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab2") {
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab3") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab4") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = true;
        this.tab5 = false;
      } else if (e === "tab5") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = true;
      }
    },
    // 资料下载链接
    downloadLink() {
    }
  }
}
</script>

<style lang="less" scoped>
#product {
  .headerBox {
    display: block;
    width: 99.1vw;
    height: 500px;
    margin-top: 82px;
    position: relative;
    h4{
      font-family: Medium;
      position: absolute;
      left: 360px;
      top: 113px;
      width: 270px;
      height: 70px;
      text-align: left;
      font-size: 50px;
      color: #fff;

    }
    p{
      font-family: Regular;
      position: absolute;
      left: 360px;
      top: 201px;
      width: 342px;
      height: 55px;
      font-size: 18px;
      text-align: left;
      color: #fff;
      line-height: 29px;
    }
    .imgProductHead {
      width: 100%;
      height: 100%;
      margin-top: -143px;
    }
    .imgProductTitle{
      position: absolute;
      left: 360px;
      top: 113px;
    }
    .imgProductText{
      position: absolute;
      left: 360px;
      top: 201px;
    }
  }

  .contentBox {
    .navHead {
      display: block;
      margin: 30px 350px;
    }

    .productBox {
      width: 1200px;
      min-height: 900px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      margin-bottom: 60px;
      .leftProducted {
        display: block;
        width: 260px;
        height: 291px;
        margin: 0 auto;
        background: #F4F5F7;
        margin-right: 30px;

        p {
          line-height: 36px;
          cursor: pointer;
          transition: all .25s linear;
          font-size: 16px;
          font-family: Regular;
          margin-bottom: 9px;

          &:hover {
            transition: all .25s linear;
            background: url("http://www.cd-rst.top:18000/static/solution/solutionNavBG.png");
            color: #FFFFFF;
          }
        }

        .isActive {
          background: url("http://www.cd-rst.top:18000/static/solution/solutionNavBG.png");
          color: #FFFFFF;
          font-size: 16px;
          font-family: Regular;
        }
      }
      .leftProduct {
        display: block;
        width: 260px;
        height: 246px;
        margin: 0 auto;
        background: #F4F5F7;
        margin-right: 30px;

        p {
          line-height: 36px;
          cursor: pointer;
          transition: all .25s linear;
          font-size: 16px;
          font-family: Regular;
          margin-bottom: 9px;

          &:hover {
            transition: all .25s linear;
            background: url("http://www.cd-rst.top:18000/static/solution/solutionNavBG.png");
            color: #FFFFFF;
          }
        }

        .isActive {
          background: url("http://www.cd-rst.top:18000/static/solution/solutionNavBG.png");
          color: #FFFFFF;
          font-size: 16px;
          font-family: Regular;
        }
      }

      .rightProduct {
        width: 910px;
        background: #FFFFFF;
        min-height: 900px;
        //产品详情头部
        .productListHead {
          width: 910px;
          height: 287.5px;
          border-bottom: 1px dashed #d2d2d2;

          .productListContent {
            width: 910px;
            height: 257px;
            display: flex;

            img {
              display: block;
              width: 300px;
              height: 220px;
            }
            .Mobile{
              display: none;
            }
            .con {
              width: 800px;
              text-align: left;

              h2 {
                //font-family: 微软雅黑;
                //width: 240px;
                font-size: 20px;
                font-family: Medium;
                font-weight: bold;
                line-height: 28px;
                color: #333333;
                opacity: 1;
                margin: 55px 0 20px 0;
              }

              p {
                width: 551px;
                height: 116px;
                font-size: 14px;
                font-family: Regular;
                font-weight: 500;
                line-height: 32px;
                color: #333333;
                opacity: 1;
              }

            }
          }
        }

        //产品详情概述
        .productListSummarize {
          width: 910px;
          height: 120px;
          margin-top: 30px;
          border-bottom: 1px dashed #d2d2d2;

          .rightBorder {
            display: flex;
            padding-left: 10px;
            border-left: 5px solid #333333;
            width: 240px;
            height: 28px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 28px;
            color: #333333;
            opacity: 1;
          }
          p {
            height: 116px;
            font-size: 14px;
            font-family: Regular;
            font-weight: 500;
            line-height: 32px;
            color: #333333;
            opacity: 1;
            text-align: left;
            margin-top: 10px;
          }
        }
        //组网拓扑图
        .productListTopologicalGraph {
          width: 910px;
          //height: 700px;
          margin-top: 30px;
          border-bottom: 1px dashed #d2d2d2;

          .rightBorder {
            display: flex;
            padding-left: 10px;
            border-left: 5px solid #333333;
            width: 240px;
            height: 28px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 28px;
            color: #333333;
            opacity: 1;
          }
          //img{
          //  width: 650px;
          //  height: 350px;
          //}
        }

        //功能特点及优势
        .productListFunction {
          width: 910px;
          height: 300px;
          margin-top: 30px;
          border-bottom: 1px dashed #d2d2d2;

          .rightBorder {
            display: flex;
            padding-left: 10px;
            border-left: 5px solid #333333;
            width: 240px;
            height: 28px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 28px;
            color: #333333;
            opacity: 1;
            margin-bottom: 20px;
          }

          p {
            height: 116px;
            font-size: 14px;
            font-family: Regular;
            font-weight: 500;
            line-height: 32px;
            color: #333333;
            opacity: 1;
            text-align: left;
            margin-top: 10px;
          }

          .productListContent {
            width: 910px;
            height: 220px;
            display: flex;
            img {
              display: block;
              width: 300px;
              height: 220px;
            }

            .con {
              width: 800px;
              text-align: left;
              margin: 40px;

              h2 {
                //font-family: 微软雅黑;
                //width: 240px;
                height: 14px;
                font-size: 14px;
                font-family: Medium;
                font-weight: bold;
                line-height: 28px;
                color: #333333;
                opacity: 1;
                margin: 13px 0 20px 0;
              }

              p {
                height: 116px;
                font-size: 14px;
                font-family: Regular;
                font-weight: 500;
                line-height: 32px;
                color: #333333;
                opacity: 1;
              }
              span{
                display: block;
              }
            }
          }

        }
        .productListFunctionMax{
          height: 480px;
          h2{
            width: 500px;
          }

        }
        .productListFunctionMin{
          height: 320px;
          h2{
            width: 500px;
          }
        }
        .productListFunctionMid{
          height: 420px;
          h2{
            width: 900px;
          }
        }
        .productListFunctionSmall {
          height: 280px;
          margin-top: 0;
        }
        // 应用场景
        .productListApplicationHeight{
          height: 750px !important;
        };
        .productListApplication {
          width: 910px;
          height: 530px;
          margin-top: 30px;
          border-bottom: 1px dashed #d2d2d2;
          .rightBorder {
            display: flex;
            padding-left: 10px;
            border-left: 5px solid #333333;
            width: 240px;
            height: 28px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 28px;
            color: #333333;
            opacity: 1;
          };
          .app-Img {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .app-font{
              position: relative;
              img {
                width: 340px;
                height: 195px;
                margin: 15px;
              }
              p {
                position: absolute;
                //bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-weight: bold;
                font-family: Regular;
                font-size: 15px;
                display: block;
                width: 86.5%;
                padding: 10px;
                background-color: #409eff59;
                height: 20px;
                bottom: 18px;
              }
            }

          }
        }
        //技术参数
        .productListTechnology {
          width: 910px;
          margin-top: 30px;

          .rightBorder {
            display: flex;
            padding-left: 10px;
            border-left: 5px solid #333333;
            width: 240px;
            height: 28px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 28px;
            color: #333333;
            opacity: 1;
            margin-bottom: 20px;
          }
          a{
            display: block;
            text-decoration: underline;
            color: #2a79ca;
            font-size: 16px;
            font-family: Regular;
          }
          p{
            display: none;
          }
          .MobileButton{
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #product {
    width: 100%;
    height: 100%;
    .headerBox{
      display: none;
    }
    .contentBox{
      //面包屑
      .navHead {
        display: none;
      }
      .productBox{
        width: 100%;
        height: 100%;
        //导航栏1
        .leftProducted{
          display: none;
        }
        //导航栏2
        .leftProduct{
          display: none;
        }
        //右侧产品内容
        .rightProduct{
          width: 100%;
          height: 100%;
          .productListHead{
            width: 100%;
            height: 590px;
            margin-top: 64px;
            padding-top: 105px;
            background-image: url("http://www.cd-rst.top:18000/static/imges/technology/product/MobileProductHeader.png");
            //background-image: url("http://www.cd-rst.top:18000/static/imges/technology/product/MobileProductHeader.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .productListContent{
              display: block;
              width: 100%;
              height: 100%;
              .con{
                width: 100%;
                height: 95px;
                margin: 0 auto;
                text-align: center;
                h2{
                  width: 100%;
                  height: 36px;
                  margin: 0 auto;
                  font-family: Medium;
                  font-size: 30px;
                  font-weight: 500;
                  letter-spacing: 0em;
                  color: #454545;
                }
                p{
                  width: 90%;
                  height: 44px;
                  margin: 0 auto;
                  font-family: Regular;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 22px;
                  letter-spacing: 0em;
                  color: #999999;
                }
              }
              img{
                display: none;
              }
              .Mobile{
                display: block;
                width: 185px;
                height: 301px;
                margin: 0 auto;
              }
            }
          }

          //产品详情概述
          .productListSummarize {
            width: 90%;
            height: 100% !important;
            background-color: #fff;
            margin: 45px auto;
            border-bottom: none;
            .rightBorder{
              position: relative;
              width: 100px;
              height: 29px;
              margin: 0 auto 37px;
              border-left: none;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #454545;
            }
            //标题下的横线
            .rightBorder:after {
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 31px;
              /* border 高度 */
              height: 2px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 29px;
              box-sizing: border-box;
            }
            p{
              width: 100%;
              height: 153px;
              font-family: Regular;
              font-size: 15px;
              font-weight: normal;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #666666;
            }
          }

          //组网拓扑图
          .productListTopologicalGraph {
            width: 100%;
            height: 100% !important;
            margin: 0 auto;
            border-bottom: none;

            .rightBorder{
              position: relative;
              width: 120px;
              height: 29px;
              margin: 0 auto 37px;
              border-left: none;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #454545;
            }
            //标题下的横线
            .rightBorder:after {
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 31px;
              /* border 高度 */
              height: 2px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 29px;
              box-sizing: border-box;
            }
            img{
              width: 100% !important;
              height: 320px !important;
            }
          }

          //功能特点及优势
          .productListFunction {
            width: 90%;
            height: 100%;
            margin: 43px auto;
            border-bottom: none;
            .rightBorder{
              position: relative;
              width: 160px;
              height: 29px;
              margin: 0 auto 37px;
              border-left: none;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #454545;
            }
            //标题下的横线
            .rightBorder:after {
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 31px;
              /* border 高度 */
              height: 2px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 29px;
              box-sizing: border-box;
            }

            .productListContent{
              width: 100% !important;
              height: 100%;
              .con{
                width: 85%;
                height: 100%;
                margin: 0 auto !important;
                h2{
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 100%;
                  height: 36px;
                  line-height: 36px;
                  margin: 0 auto 30px;
                  font-family: Medium;
                  font-size: 30px;
                  font-weight: 500;
                  letter-spacing: 0em;
                  color: #454545;
                }
                p{
                  width: 100%;
                  height: 100%;
                  margin: 0 auto;
                  font-family: Regular;
                  font-size: 15px;
                  font-weight: normal;
                  line-height: 24px;
                  letter-spacing: 0.05em;
                  color: #3D3D3D;
                }
              }
              img{
                display: none;
                width: 100%;
              }
            }
          }

          // 应用场景
          .productListApplication {
            width: 90%;
            height: 100% !important;
            margin: 0 auto;
            border-bottom: none;
            .rightBorder{
              position: relative;
              width: 100px;
              height: 29px;
              margin: 0 auto 37px;
              border-left: none;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #454545;
            }
            //标题下的横线
            .rightBorder:after {
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 31px;
              /* border 高度 */
              height: 2px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 29px;
              box-sizing: border-box;
            }
            .app-Img{
              display: flex;
              flex-direction: column;
              justify-content: left;
              flex-wrap: nowrap;
              .app-font{
                overflow: hidden;
                img{
                  width: 100%;
                  height: 184px;
                  margin-bottom: 28px;
                }
                p {
                  position: absolute;
                  bottom: 32px;
                  left: 50%;
                  transform: translateX(-50%);
                  color: #fff;
                  font-weight: bold;
                  font-family: Regular;
                  font-size: 15px;
                  line-height: 40px;
                  margin-left: 5px;
                  width: 100%;
                  background-color: #409eff59;
                  height: 40px;
                }
              }
            }

          }

          //技术参数
          .productListTechnology {
            position: relative;
            width: 90%;
            height: 100%;
            margin: 20px auto 34px;
            .rightBorder{
              position: relative;
              width: 100px;
              height: 29px;
              margin: 0 auto 37px;
              border-left: none;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #454545;
            }
            //标题下的横线
            .rightBorder:after {
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 31px;
              /* border 高度 */
              height: 2px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 29px;
              box-sizing: border-box;
            }
            img{
              width: 100% !important;
            }
            a{
              display: none;
            }
            p{
              display: block;
              width: 100%;
              height: 21px;
              text-align: left;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #3D3D3D;
            }
            .MobileButton{
              display: block;
              position: absolute;
              right: 0;
              top: 60px;
              width: 65px;
              height: 31px;
              text-align: center;
              line-height: 31px;
              border-radius: 5px;
              background: #0564AF;
              a{
                display: block;
                text-decoration: none;
                width: 100%;
                height: 19px;
                font-family: Regular;
                font-size: 13px;
                font-weight: normal;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                z-index: 0
              }
            }
          }
        }
      }
    }
  }
}
</style>
